//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ACCESS_TOKEN } from '@/store/mutation-types';

const types = {
  404: {
    img: '/img/404.svg',
    title: '404',
    desc: 'Sorry, the page you visited does not exist or is still under development'
  }
};
import { mixin } from '@/utils/mixin';
import { mapActions } from 'vuex';

export default {
  name: 'Exception',
  mixins: [mixin],
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  data() {
    return {
      config: types
    };
  },
  created() {
    this.$router.replace('/');
  },
  computed: {
    isLogin() {
      return this.$ls.get(ACCESS_TOKEN);
    }
  },
  methods: {
    ...mapActions('user', ['Logout']),
    handleToHome() {
      this.$router.replace({ path: '/' });
    },
    handleLogout() {
      this.Logout()
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          this.$message.error({
            title: 'Error',
            description: err.message
          });
        });
    }
  }
};
