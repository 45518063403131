var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exception" }, [
    _c("div", { staticClass: "imgBlock" }, [
      _c("div", {
        staticClass: "imgEle",
        style: { backgroundImage: "url(" + _vm.config[_vm.type].img + ")" }
      })
    ]),
    _c("div", { staticClass: "content" }, [
      _c("h1", [_vm._v(_vm._s(_vm.config[_vm.type].title))]),
      _c("div", { staticClass: "desc" }, [
        _vm._v(" " + _vm._s(_vm.config[_vm.type].desc) + " ")
      ]),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleToHome } },
            [_vm._v(" Home ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }